.lang-nav {
    display: block;
    position: absolute;
    width: auto;
    height: auto;
    top: 6px;
    right: 10px;
    font-size: 0;
    transform: translateX(0);
    transition: transform 0.5s ease-out, visibility 0.3s ease, opacity 0.3s ease;
    visibility: visible;
    opacity: 1;
}

.lang-nav--slide-out {
    transform: translateX(-150%);
}

header.is-sticky .main-menu:not(.open) .lang-nav {
    visibility: hidden;
    opacity: 0;
}

.lang-nav li {
    display: inline-block;
}

.lang-nav a {
    display: inline-block;
    padding: 5px 10px 5px 10px;
    margin-left: 2px;
    color: black;
    text-decoration: none;
    font-size: 14px!important;
    line-height: 14px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    transition: background 0.4s, color 0.4s;
}

.lang-nav li.active a {
    background: #6E6E6E;
    color: white;
    border-radius: 2px;
}

.lang-nav a:focus,
.lang-nav a:hover{
    color: @tint;
   
}
.lang-nav li.active a:focus,
.lang-nav li.active a:hover {
    color: @tint;
    background: #ffffff;
}

.lang-nav a:active,
.lang-nav li.active a:active{
    color: #222;
}

@media @big-devices-and-smaller {
    .lang-nav{
        position: static;
        background: #222;
        width: 100%; // Prevents close event on ".js-main-nav-panel"
        padding: 8px 12px;
        visibility: hidden;
        opacity: 0;

        // display: none;

        .open & {
            visibility: visible;
            opacity: 1;
        }
    }

.lang-nav a {
    display: inline-block;
    min-width: 25px;
    padding: 3px 5px 3px 5px;
    margin-left: 8px;
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 14px!important;
    line-height: 14px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    background:#222222;
    transition: background 0.4s, color 0.4s;
    border-radius: 0;
}

.lang-nav li:first-child a {
    margin-left: 0;
}

.lang-nav li.active a {
    background: white;
    color: #222222;
    border-radius: 0;
}

.lang-nav a:focus,
.lang-nav a:hover{
    color: @tint;
   
}
.lang-nav li.active a:focus,
.lang-nav li.active a:hover {
    color: @tint;
    background: white;
}

.lang-nav a:active,
.lang-nav li.active a:active{
    color: #222;
     background: white;
   
}


}

@media @screen-lg-min {
    .facelift-2025 .main-menu .lang-nav-element {
        display: none;
    }
}