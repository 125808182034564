.main-menu {
    display: block;
    opacity: 1;
    position: absolute;
    width: 100%;
    height: auto;
    top: 82px;
    padding-left: 360px;
    line-height: 32px;
    transition: top 0.3s ease-out, opacity 0.4s;
    z-index: 10;

    -webkit-tap-highlight-color: rgba(0,0,0,0); // Prevent black shadow on tap event

    @media @screen-lg-min {
        .facelift-2025 & {
            position: static;
            padding-left: 0;
            grid-area: main-nav;
        }
    }
}

header.is-sticky .main-menu {
    top: 92px;
}

.main-nav {
    display: block;
    position: absolute;
    top: 6px;
    font-size: 0;
    height: 62px;
    margin-bottom: 15px;

    .facelift-2025 & {
        @media @screen-lg-min {
            position: static;
            display: flex;
            gap: 1.5rem;
            margin-bottom: 0;
            height: auto;
        }
    }
}

.main-nav.main-nav--default,
.main-nav.main-nav--reiseportal {
    left: 135px;
    z-index: 20;
}

.main-nav.main-nav--val,
.main-nav.main-nav--kombitickets {
    left: 220px;
}

.main-nav.main-nav--rail-tours {
    left: 195px;

    & > li > a:not(.nav-item-toggle) {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.main-nav.main-nav--klimaticket {
    left: 265px;
}

@media @big-devices-and-smaller {
    .main-nav.main-nav--default,
    .main-nav.main-nav--reiseportal {
        left: auto;
    }

    header.is-sticky .main-menu {
        top: 0;
    }
}

.main-nav>li {
    display: inline-block;
    background: transparent;
    height: 70px;
    position: relative;
    margin-left: 5px;
    margin-right: 10px;

    .facelift-2025 & {
        @media @screen-lg-min {
            margin-inline: 0;
            height: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.main-nav>li>a {
    display: inline-block;
    color: black;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    padding-left: 5px;
    padding-right: 12px;
    margin-right: 0;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    transition: background 0.4s, color 0.4s;

    .facelift-2025 & {
        font-weight: 400;
        text-transform: none;
        font-family: @font-fallback;
        padding-right: 0;
    }
}

.main-nav .nav-item-toggle {
    padding: 0;

    .facelift-2025 &--bottom {
        background: transparent;
        border: none;
        color: #ADA8A8;
        order: 1;

        &:focus-visible {
            outline-offset: 2px;
            color: @tint;
        }
    }
}

.main-nav>li>div {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    min-width: 285px;
    top: 62px;
    background: transparent;
    padding-top: 0;
    z-index: 1;
}

.main-nav--reiseportal>li:last-child>div,
.main-nav--postbus>li:last-child>div{
    right: 0;
    left: auto;
}

.main-nav>li>div>div {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1280px;
    background: #222222;
    height: auto;
    color: white;
    font-size: 16px;
    margin-top: 0;
}


.main-nav>li>div>div ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.main-nav>li>div>div ul li {
    position: relative;

    display: block;
    width: 100%;
}

.open-link {
    display: none;
}

ul.main-nav>li.open>div {
    display: block;
}

ul.main-nav>li.open>a:after,
ul.main-nav>li.active>a:after {
    content: '';
    display: block;
    position: absolute;
    background: transparent;
    border-bottom: 7px solid @tint;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;
    transition: height 0.3s ease-out;

    .facelift-2025 & {
        border-bottom: 3px solid @tint;
        height: 100%;
    }
}

header.is-sticky .main-nav>li {
    height: 52px;

    .facelift-2025 & {
        height: 4rem;
    }
}

header.is-sticky .main-nav>li.open>a:after,
header.is-sticky .main-nav>li.active>a:after {
    height: 52px;

    .facelift-2025 & {
        height: 100%;
    }
}

header.is-sticky .main-nav>li>div {
    top: 52px;

    .facelift-2025 & {
        top: 100%;
    }
}

ul.main-nav ul {
    width:100%;
}

ul.main-nav ul>li>a {
    color: #fff;
    background: transparent;
    width: 100%;
    display: block;
    position: relative;
    text-decoration: none;
    padding: 4px 50px 4px 12px;
    border-bottom: 1px solid #464646;
}

ul.main-nav ul>li>a span.ic-external-link {
    position: absolute;
    top: 0;
    right: 0;

    display: inline-block;
    width: 36px;
    height: 100%;

    background-position: center center;
    background-size: 16px;
    opacity: 0.75;
}

ul.main-nav ul>li>a:hover span.ic-external-link {
    opacity: 1;
}

ul.main-nav ul>li>a:focus,
ul.main-nav ul>li>a:hover {
    color: #fff;
    background: #383736;
}

ul.main-nav ul>li>a:active {
    color: #222;
    background: #F0F0F0;
}

.main-nav>li.main-nav-home {
    display: none;
}

/**
 * Menu Level 3
 */

.main-nav .nav-sub-panel-link {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 100%;
    padding: 0;
    background: transparent;
    border: none;
    border-left: 1px solid #464646;
    cursor: pointer;
    color: #F0F0F0;
    text-align: center;
}

.main-nav .nav-sub-panel-link:focus {
    color: #F0F0F0;
    outline-color: #F0F0F0;
}

.main-nav .nav-sub-panel-link .ic-menu-item-forward {
    vertical-align: middle;
}

.main-nav ul > li > ul {
    position: absolute;
    top: -1px; // Border correction
    left: 100%;
    display: none;
    background: #383736;
    border-top: 1px solid #464646;
    border-bottom: 1px solid #464646;
}

.main-nav ul > li:first-child > ul {
    top: 0; // Border correction
}

.main-nav ul > li > ul > li > a:focus,
.main-nav ul > li > ul > li > a:hover {
    background: #4d4d4d;
}

.main-nav ul > li > ul > li > a:active {
    background: #F0F0F0;
}

.main-nav ul > li:hover > ul,
.main-nav ul > li.has-active-sub-panel > ul {
    display: block;
}

.main-nav--reiseportal > li:last-child ul > li > ul,
.main-nav--postbus > li:last-child ul > li > ul {
    right: 100%;
    left: auto;
}

.main-nav ul > li:hover {
    background: #383736
}

.main-menu.open.has-active-sub-panel .main-nav {
    overflow: hidden;
}

.main-nav-close-link {
    display: none;
}

.main-nav>li a.open-link {
    display: none;
}

@media @big-devices-and-smaller {
    ul.main-nav{
        width:100%;
    }
    .main-nav>li.main-nav-home {
        display: block;
    }
    .main-menu {
        position: fixed;
        display: block;
        width: @menuWidthLarge;
        height: 100%;
        left: 0;
        top: 0;
        background: #383736;
        color: white;
        transition: margin-left 0.4s linear, visibility 0.4s linear, opacity 0.4s;
        margin-left: -@menuWidthLarge;
        padding-left: 0;
        pointer-events: none;
        overflow: hidden;
        z-index: 10;
        visibility: hidden; // Hidden because of tab navigation
    }
    .main-menu.open {
        top: 50px;
        visibility: visible;
    }
    .main-nav>li>div {
        min-width: auto;
    }
    .main-nav ul > li:first-child > ul {
        top: 50px; // Override border correction
    }
    .main-nav--reiseportal > li:last-child > div,
    .main-nav--postbus > li:last-child > div {
        right: auto;
        left: 0;
    }
    .main-menu * {
        pointer-events: all;
    }
    .ocopen .main-nav-panel::after {
        content: " ";
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #000;
        opacity: 0.2;
        pointer-events: all;
        z-index: 4;
    }
    ul.main-nav {
        position: static;
        height: auto;
    }
    .main-menu,
    body,
    html {
        -webkit-overflow-scrolling: touch;
    }
    .ocopen .main-menu {
        left: 0;
        top: 0;
        z-index: 5;
        overflow-x: hidden;
    }
    ul.main-nav>li {
        width: 100%;
        margin: 0;
        padding: 0;
        height: auto;
    }
    .main-nav li.open>div {
        position: relative;
        padding-top: 0;
        top: 0;
    }
    .main-nav>li>div,
    .main-nav>li.open>div {
        display: none;
        position: relative;
        left: auto;
        /* top: 150px; */
        background: 0 0;
        padding-top: 0;
        z-index: auto;
        // z-index: 1;
    }
    .main-nav li>div>div {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        color: white;
        background: #383736;
        height: auto;
        font-size: 16px;
        top: 0;
    }
    .main-nav>li>a {
        width: 100%;
        color: #F0F0F0;
        background: #222;
        height: 50px;
        font-weight: normal;
        line-height: 50px;
        border-bottom: 1px solid #464646;
    }
    .main-nav .nav-item-toggle {
        display: none;
    }
    ul.main-nav>li>a>i:before {
        content: "";
    }
    ul.main-nav>li.open>a>i:before {
        content: "";
    }
    ul.main-nav>li.open>a:after {
        display: none;
    }

    ul.main-nav>li.active>a {
        background-color: #383736;

        .facelift-2025 &::after,
        &::after {
            border-bottom: none;
        }
    }

    .main-nav ul > li > ul {
        position: fixed;
        top: 50px;
        left: -@menuWidthLarge;
        bottom: 0;
        z-index: 20;
        display: block;
        width: 100%;
        max-width: @menuWidthLarge;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background-color: #222222;
        border-top: none;
        border-bottom: none;
        visibility: hidden;
        transition: left 0.5s ease-out, visibility 0.5s ease-out; // Use left instead of transform, because IE dosn't perform as expected (fixed positon and transform)
    }

    .main-nav--reiseportal > li:last-child ul > li > ul,
    .main-nav--postbus > li:last-child ul > li > ul {
        right: auto;
        left: -@menuWidthLarge;
    }

    .main-nav ul > li.has-active-sub-panel > ul,
    .main-nav--reiseportal > li:last-child ul > li.has-active-sub-panel > ul,
    .main-nav--postbus > li:last-child ul > li.has-active-sub-panel > ul {
        left: 0;
        visibility: visible;
        transition: left 0.5s ease-out;
    }

    .nav-sub-panel-title {
        position: absolute;
        top: 0;
        right: 55px;
        left: 45px;

        display: block;
        height: 50px;
        overflow: hidden;

        transform: translateX(-100%) translateX(-50px);;
        transition: transform 0.5s ease-out;

        font-size: 18px;
        line-height: 50px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .nav-sub-panel-btn-back {
        position: absolute;
        top: 0;

        display: block;
        width: 50px;
        height: 50px;
        z-index: 30;

        padding: 0 14px;

        background-color: transparent;
        background-position: 14px center;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        transform: translateX(-100%);
        transition: transform 0.5s ease-out;

        color: #FFFFFF;
    }

    .nav-sub-panel-element-slide-in {
        transform: translateX(0%);
    }

    header.is-sticky .main-nav>li {
        height: auto;
    }

    header.is-sticky .main-nav>li>div {
        top: 0;
    }

    .main-nav li .ic-nav-expand {
        display: none;
    }
    .main-nav-close-link {
        display: block;
        position: absolute;
        right: 0;
        width: 50px;
        height: 50px;
        line-height: 48px;
        z-index: 20;
        min-height: 50px;
        color: white;
        background: #383736;
        text-align: center;
        cursor: pointer;
    }
    .main-nav-close-link span {
           vertical-align: middle;
    }
    .main-nav>li a.open-link {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: #222222;
        color: white;
        width: 50px;
        height: 50px;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        font-size: 20px;
        border-left: 1px solid #464646;
    }
    .main-nav>li a {
        padding-left: 12px;
    }
    ul.main-nav li a:focus,
    ul.main-nav li a:hover {
        background-color: #383736;
    }
    ul.main-nav li a:active {
        color: white;
        background: #666;
    }
    ul.main-nav ul>li>a:focus,
    ul.main-nav ul>li>a:hover {
        background-color: #565656;
    }
    .main-nav>li a.open-link:hover {
        background: transparent;
    }
}

@media @small-devices {
    .main-menu {
        width: @menuWidthMedium;
        margin-left: -@menuWidthMedium;
    }

    .main-nav ul > li > ul {
        left: -@menuWidthMedium;
        max-width: @menuWidthMedium;
    }
    
    .main-nav--reiseportal > li:last-child ul > li > ul,
    .main-nav--postbus > li:last-child ul > li > ul {
        left: -@menuWidthMedium;
    }
}

@media @mini-devices {
    .main-menu {
        width: @menuWidthSmall;
        margin-left: -@menuWidthSmall;
    }

    .main-nav ul > li > ul {
        left: -@menuWidthSmall;
        max-width: @menuWidthSmall;
    }
    
    .main-nav--reiseportal > li:last-child ul > li > ul,
    .main-nav--postbus > li:last-child ul > li > ul {
        left: -@menuWidthSmall;
    }
}

.main-menu-nav-panel {
    @media @big-devices-and-smaller {
        position: relative;
        margin-top: 50px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .facelift-2025 & {
        @media @screen-xxl-min {
            justify-self: center;
        }
    }

    .main-menu.open.has-active-sub-panel & {
        overflow: hidden;
    }

    &__mobile-links {
        display: none;

        .edit-mode & {
            position: absolute;
            top: -80px;
            left: 0;

            display: block;
            height: 25px;
            overflow: hidden;
        }

        @media @big-devices-and-smaller {
            position: relative;

            display: block;
            margin: 0 0 15px;
            padding: 0;

            .edit-mode & {
                position: relative;
                top: auto;
                left: auto;

                height: auto;
            }
        }
    }
}

.nav-list-mobile {
    margin: 0;
    padding: 0;

    list-style: none;

    &__link {
        position: relative;

        display: block;
        padding: 4px 50px 4px 12px;

        color: #fff;
        text-decoration: none;

        .ic-external-link {
            position: absolute;

            width: 32px;
            height: 32px;

            background-position: center center;
            background-size: 16px;
        }
    
        &:hover {
            background: #676767;
        }
    }

    &__dl-meta {
        padding: 0 8px;

        font-size: 14px;
    }
}
