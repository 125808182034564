ul.service-nav {
    opacity: 1;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 78%, rgba(238, 238, 238, 1) 100%);
    transition: top 0.3s ease-out, opacity 0.4s ease-out;

    .facelift-2025 & {
        position: static;
        width: auto;
        height: 3rem;
        line-height: 3rem;
        grid-area: service-nav;
        background: transparent;
        overflow: hidden;

        @media @screen-xl-min {
            height: 4rem;
        }

    }
}

ul.service-nav.overlay {
    display: none;
}

li.ticketshop-link {
    height: 50px;
}

ul.service-nav li a {
    display: inline-block;
    border: 0;
    color: #000;
    height: 50px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s;
    padding-right: 10px;
    padding-left: 10px;
    vertical-align: text-bottom;
}

.facelift-2025 .service-nav__link {
    background: transparent;
    border: none;
    color: #565151;
    font-size: .875rem;
    height: 100%;
    display: inline-flex;
    align-items: center;
    gap: .5rem;
}

ul.service-nav li a:focus,
ul.service-nav li a:hover,
ul.service-nav li .active {
    color: @tint;
    /*  background: #fff; */
    text-decoration: none;
}

ul.service-nav li .active .ic-contrast-gray {
    transform: rotate(180deg);
    transform-origin: 10px center;

    @media @big-devices-and-smaller {
        transform-origin: 12px 11px;
    }
}

header.is-sticky ul.service-nav li .active .ic-contrast-gray {
    transform-origin: 12px 11px;
}

ul.service-nav li a:active {
    color: #222;
    background: #FFF;
    border-color: #222;
}

ul.service-nav li.ticketshop-link .inner,
ul.service-nav li.ticketshop-link a {
    background: @tint;
    color: #fff;
    margin-left: 8px;
    padding-right: 10px;
    padding-left: 12px;
}

ul.service-nav li.ticketshop-link .inner:focus,
ul.service-nav li.ticketshop-link .inner:hover,
ul.service-nav li.ticketshop-link a:focus,
ul.service-nav li.ticketshop-link a:hover {
    color: @tint;
    background: #fff;
    text-decoration: none;
}

ul.service-nav li.ticketshop-link .inner:active,
ul.service-nav li.ticketshop-link a:active {
    color: #222;
    background: #FFF;
    border-color: #222;
}

header ul.service-nav li span[class^='ic'] {
    margin-right: 3px;
    margin-top: -3px;
}

header.is-sticky ul.service-nav {
    background: transparent;
    max-width: 275px;
    top: 88px;
    right: 8px;

    .facelift-2025 & {
        max-width: none;
    }
}

header.is-sticky ul.service-nav li span:not([class^='ic']) {
    display: inline-block;
    position: absolute;
    top: -2000px;
    left: -2000px;

    @media @screen-lg-min {
        .facelift-2025 & {
            position: static;
        }
    }
}

header.is-sticky ul.service-nav span.ic-nav-forward {
    display: none!important;
}

header.is-sticky ul.service-nav li span[class^='ic'] {
    vertical-align: text-top;
    background-size: 18px 18px;
    width: 20px;
    height: 20px;
    margin-right: 0;
    margin-top: 0;
}

header.is-sticky {
    :not(.active):not(:active):not(:focus):not(:hover) {
        .service-nav-icons-contrast;
    }
}

header.is-sticky ul.service-nav li a {
    line-height: 46px;

    .facelift-2025 & {
        line-height: 48px;
    }
}

header.is-sticky ul.service-nav li.ticketshop-link .inner,
header.is-sticky ul.service-nav li.ticketshop-link a {
    margin-left: 4px;
    padding-right: 14px;
    padding-left: 14px;
}

@media @big-devices-and-smaller {
    ul.service-nav {
        background: transparent;
        max-width: 280px;
        top: 8px;
        right: 8px;

        .facelift-2025 & {
            max-width: none;
        }
    }
    header.is-sticky ul.service-nav {
        background: transparent;
        max-width: 280px;
        top: 8px;
        right: 8px;

        .facelift-2025 & {
            max-width: none;
        }
    }
    .facelift-2025 .service-nav__link span:not([class^='ic']),
    ul.service-nav li span:not([class^='ic']) {
        display: inline-block;
        position: absolute;
        top: -2000px;
        left: -2000px;
    }
    ul.service-nav span.ic-nav-forward {
        display: none!important;
    }
    header ul.service-nav li span[class^='ic'] {
        vertical-align: text-top;
        background-size: 18px 18px;
        width: 20px;
        height: 20px;
        margin-right: 0;
        margin-top: 0;
    }

    :not(.active):not(:active):not(:focus):not(:hover) > {
        .service-nav-icons-contrast;
    }

    ul.service-nav li.ticketshop-link [class^='ic'] {
        opacity: 1.0;
    }
    ul.service-nav li a {
        padding-right: 10px;
        padding-left: 10px;
        line-height: 46px;
    }
    ul.service-nav li.ticketshop-link .inner,
    ul.service-nav li.ticketshop-link a {
        margin-left: 4px;
        padding-right: 14px;
        padding-left: 14px;
    }
}

@media @small-devices {
    ul.service-nav,
    header.is-sticky ul.service-nav {
        top: 0;
        right: 0;
    }
}

@media @mini-devices {
    header.is-sticky ul.service-nav li .inner,
    header.is-sticky ul.service-nav li a,
    ul.service-nav li a {
        padding-right: 6px;
        padding-left: 6px;
    }
}

@media @mini-mini-devices {
    header.scis-sticky ul.service-nav,
    ul.service-nav {
        max-width: 190px;
    }

    header.is-sticky ul.service-nav li.ticketshop-link .inner,
    ul.service-nav li.ticketshop-link .inner,
    header.is-sticky ul.service-nav li.ticketshop-link a,
    ul.service-nav li.ticketshop-link a {
        padding-right: 8px;
        padding-left: 9px;
    }
}

ul.service-nav--skewed {
    background: #ffffff;

    li .inner,
    li a {
        background-color: #f5f5f5;
        transform: skew(-@shearAngle);

        span {
            display: inline-block;
            transform: skew(@shearAngle);
        }
    }

    li:first-child a {
        padding-left: 15px;
    }

    li:nth-last-child(2) a {
        padding-right: 15px;
    }

    li:last-child {
        display: inline-block;
        height: 50px;
        margin-left: -15px;
        overflow: hidden;

        .inner,
        a {
            margin-left: 0;
            padding-right: 25px;

            transform: skew(-@shearAngle) translateX(15px);

            @media @big-devices-and-smaller {
                padding-right: 30px;
                padding-left: 15px;
            }
        }
    }

    li:not(.ticketshop-link) a {
        margin-left: -2px; // Correct thin gap

        @media @big-devices-and-smaller {
            background-color: #ffffff;
        }
    }

    li .active .ic-contrast-gray {
        transform: rotate(180deg) skew(@shearAngle);
        // transform-origin: 12px center;
    }

    header.is-sticky & {
        li:last-child {
            .inner,
            a {
                margin-left: 0;
                padding-right: 30px;
                padding-left: 15px;
            }
        }

        li:not(.ticketshop-link) a {
            background-color: #ffffff;
        }
    }
}

.service-nav-icons-contrast() {
    > .ic-contrast-gray {
        background-image: url(../images/icons/spritemap-darkgray.svg#contrast-view);
    }

    > .ic-search {
        background-image: url(../images/icons/spritemap-darkgray.svg#search-view);
    }

    > .ic-faq {
        background-image: url(../images/icons/spritemap-darkgray.svg#question-view);
    }

    > .ic-external-link {
        background-image: url(../images/icons/spritemap-darkgray.svg#linkextern-view);
    }

    > .ic-map {
        background-image: url(../images/icons/spritemap-darkgray.svg#map-view);
    }

    > .ic-contact-mail {
        background-image: url(../images/icons/spritemap-darkgray.svg#mail-view);
    }
}


.facelift-2025 header ul.service-nav li.ticketshop-link {
    margin-left: -15px;

    a {
        margin-left: 0;
        padding-inline: 12px 25px;
        height: 100%;
        transform: skew(-@shearAngle) translateX(15px);

        display: inline-flex;
        align-items: center;
        gap: .5rem;

        .service-nav__link-content,
        .icon {
            display: block;
            transform: skew(@shearAngle);
        }

        .service-nav__link-content {
            @media @screen-lg-max {
                position: absolute;
                width: 1px;
                height: 1px;
                margin: -1px;
                padding: 0;
                overflow: hidden;

                border: 0;
                clip-path: inset(50%);

                white-space: nowrap;
            }
        }
    }
}

.facelift-2025 header ul.service-nav li.loyalty-program-link,
.facelift-2025 header.is-sticky ul.service-nav li.loyalty-program-link {
    a {
        transform: skew(-@shearAngle);
        padding-inline: 1rem;
        margin-inline: .25rem;
        min-width: 0;
        height: 100%;

        display: inline-flex;
        align-items: center;
        gap: .5rem;

        @media @screen-lg-max {
            padding-inline-end: .5rem;
        }

        .service-nav__link-content,
        span {
            display: block;
            transform: skew(@shearAngle);
            background-size: 20px;
            margin-top: -3px;
            margin-right: 0;
        }

        .service-nav__link-content {
            @media @screen-lg-max {
                position: absolute;
                width: 1px;
                height: 1px;
                margin: -1px;
                padding: 0;
                overflow: hidden;

                border: 0;
                clip-path: inset(50%);

                white-space: nowrap;
            }
        }
    }
}

