/** Slider */

.image-title-slider.carousel {
    margin: 0;

    .facelift-2025 & {
        margin-inline: auto;
    }

    .carousel__view {
        margin-bottom: 0;
    }

    /** Forward/Backward */

    .carousel__control-backward,
    .carousel__control-forward {
        top: 0;

        width: 110px;

        transform: none;

        > [class*="ic-nav"] {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 60px;
            height: 60px;
            vertical-align: middle;

            background-size: 50px;
            transform: translate(-50%, -50%);
            background-position: 50%;

            text-align: center;

            @media @small-medium-devices {
                width: 30px;
                height: 30px;

                background-size: 25px;
            }
        }

        @media @small-medium-devices {
            display: block;
            width: 45px;
            height: 100%;
        }

        @media @small-devices {
            width: 30px;
        }

        .postbus &:hover,
        .postbus &:focus {
            > [class*="ic-nav"] {
                background-color: #fff;
            }
        }
    }

    /** Controls */

    .carousel__controls {
        position: absolute;
        left: 125px;
        bottom: 5%;

        z-index: 10;

        @media @small-medium-devices {
            left: 0;
            bottom: 0;
        }
    }

    /** Play/Pause */

    .carousel__control-play {
        margin-left: 0;
        margin-right: 30px;

        @media @medium-devices-and-smaller {
            padding: 2px 0;
        }

        @media @small-medium-devices {
            display: inline-flex;
            padding: 14px 5px;
            margin-right: 12px;
            min-width: 48px;
            min-height: 48px;
            align-items: end;
        }
    }

    @media @medium-devices-and-smaller {
        .ic-play-white,
        .ic-pause-white {
            height: 16px;

            background-size: 14px 14px;
        }
    }

    /** Indicators */

    .carousel__indicator::after {
        background-color: #ffffff;
    }

    .carousel__indicator--active::after,
    .carousel__indicator:active::after,
    .carousel__indicator:focus::after,
    .carousel__indicator:hover::after {
        background-color: @tint;
    }

    @media @small-medium-devices {
        .carousel__indicators {
            display: none;
        }
    }
}

/** Forward/Backward (Outside of .image-title-slider to not overwrite default hover styles) */

.carousel__control-forward .ic-nav-forward {
    background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}

.carousel__control-backward .ic-nav-backward {
    background-image: url(../images/icons/spritemap-white.svg#arrow_left-view);
}

@media @small-medium-devices {
    .carousel__control-forward,
    .carousel__control-backward {
        width: 45px;

        cursor: pointer;
    }

    .carousel__control-forward .ic-nav-forward,
    .carousel__control-backward .ic-nav-backward {
        width: 30px;
        height: 30px;

        background-size: 25px;
    }

    .image-title-slider.carousel {
        .carousel__control-backward {
            bottom: 48px;
            height: unset;

            .ic-nav-backward {
                top: ~"calc(50% + 24px)";
            }
        }
    }
}

@media @small-devices {
    .carousel__control-forward,
    .carousel__control-backward {
        width: 30px;
    }
}

/** Image-Title Component */

.image-title {
    position: relative;

    width: 100%;
}

.image-title__image {
    width: 100%;
    height: auto;
}

.image-title-slider .image-title-slider-item__gradient {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0.9;
}

.image-title__body {
    position: absolute;
    top: 50%;
    left: 125px;

    max-width: 35%;

    transform: translateY(-50%);

    color: #FFFFFF;
}

.image-title__body h1 {
    margin: 0;

    font-size: 60px;
}

.image-title__body p {
    margin: 0 0 20px;

    font-size: 24px;
}

.image-title__body--right {
    right: 125px;
    left: auto;
}

@media @medium-devices-and-smaller {
    .image-title__body h1 {
        font-size: 48px;
    }

    .image-title__body p {
        font-size: 20px;
    }
}

@media @small-medium-devices {
    .image-title {
        margin-bottom: 40px;
    }

    .image-title__body {
        position: static;
        top: 0;
        left: 0;

        max-width: none;
        padding: 20px;

        background: #FFFFFF;
        transform: translateY(0);

        color: #666666;
        text-align: center;
    }

    .image-title__body h1 {
        font-size: 35px;
    }

    .image-title__body p {
        font-size: 16px;
    }

    .image-title__body--night {
        background: #111B42;

        color: #FFFFFF;
    }

}

/** Hero-Teaser Element */

.image-title-slider .image-title-teaser > div {
    top: 45%;

    max-width: 50%;
}

@media @medium-devices-and-smaller {
    .image-title-slider .image-title-teaser > div,
    .image-title-slider .image-title-teaser.right > div {
        top: 40%;

        transform: translateY(-40%);
    }

    .image-title-slider .image-title-teaser > div {
        padding: 0 0 0 125px;
    }

    .image-title-slider .image-title-teaser.right > div {
        padding: 0 125px 0 0;
    }
}

@media @small-medium-devices {
    .image-title-slider .image-title-teaser > div,
    .image-title-slider .image-title-teaser.right > div {
        top: auto;

        max-width: none;
        padding: 30px;

        transform: none;
    }

    .image-title-slider .image-title-teaser div > p {
        overflow: hidden;
        font-size: 16px;
    }
}

@media @small-devices  {
    .image-title-slider .image-title-teaser > div,
    .image-title-slider .image-title-teaser.right > div {
        padding: 20px 30px;
    }
}
